import { KeycloakOptions } from 'keycloak-angular/lib/core/interfaces/keycloak-options';

const keycloakOptions: KeycloakOptions = {
  config: {
    //url: 'http://localhost:8180',
    url: 'https://accounts.blockcity.tech',
    realm: 'block-dev',
    clientId: 'block-management-frontend-client',
  },
  initOptions: {
    checkLoginIframe: false,
  },
  enableBearerInterceptor: true,
};

export const environment = {
  production: false,
  //baseUrl: 'http://localhost:8080',
  baseUrl: 'https://api.dev.server.blockcity.tech',
  stationManagerBaseUrl: 'https://station-manager-api.dev.server.blockcity.tech',
  googleMapsKey: '',
  autoRefreshInterval: 33000,
  keycloakOptions: keycloakOptions,
  adminPageVersion: '1.0.0-0e5463c3ac4a0322b622227829c879faa306f6f7'
};
